<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-8">
        <p>
          You can update the sales emails below. Please note the customer name, job number and site
          address will be added to the top of the email. The sales person's name will be added to
          the bottom.
        </p>
      </v-col>
    </v-row>
    <v-row :key="index" v-for="(email, index) in emails">
      <v-col class="col-8">
        <v-textarea
            :label="`Email #${(index + 1)} text`"
            v-model="emails[index].text"></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-8">
        <v-btn @click="saveChanges">Save</v-btn>
      </v-col>
    </v-row>
    <v-dialog
        v-model="showMessage"
        max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="orange darken-2"
              text
              @click="showMessage = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'UpdateSalesEmailPage',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      emails: [],
      message: '',
      showMessage: false,
    };
  },
  methods: {
    getEmails() {
      axios.get(`/salesEmailTemplates/getAll.json?token=${this.token}`)
          .then((response) => {
            this.emails = response.data.emails;
          })
          .catch((error) => {
            console.log(error);
            this.hide = false;
          });
    },
    saveChanges() {
      const postData = this.emails;
      axios.post(`/salesEmailTemplates/update.json?token=${this.token}`, postData)
          .then((response) => {
            this.message = response.data.message;
            this.showMessage = true;
          })
          .catch((error) => {
            console.log(error);
            this.hide = false;
          });
    },
  },
  mounted() {
    this.getEmails();
  },
};
</script>
